import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import getConfig from 'next/config';

/* global VERSION */
/* global APP_ENV */

const { serverRuntimeConfig, publicRuntimeConfig } = getConfig();
Bugsnag.start({
  apiKey: serverRuntimeConfig.BUGSNAG_API_KEY || publicRuntimeConfig.BUGSNAG_API_KEY,
  enabledReleaseStages: [APP_ENV],
  appVersion: VERSION,
  plugins: [new BugsnagPluginReact()],
  releaseStage: APP_ENV,
});

export default Bugsnag;
