import React from 'react';
import { initStore } from '~/state/store';
import withState from '~/hocs/withState';
import NotFound from '../components/NotFound/NotFound';
import bugsnagClient from '~/util/bugsnag';

class Error extends React.Component {
  static getInitialProps({ res, err }) {
    const statusCode = res ? res.statusCode : err ? err.statusCode : null;
    if (err) bugsnagClient.notify(err);
    return { statusCode };
  }

  render() {
    return <NotFound />;
  }
}

export default withState({ createStore: initStore })(Error);
